import { FunctionComponent, useCallback } from "react";
import { TwitterIcon } from "../common/icons";
import { RecommendPlanet } from "@/types/api";
import Image from "next/image";
import { amountDesc } from "@/utils/format";
import { Button } from "@mantine/core";
import Router from "next/router";

const SpaceListItem: FunctionComponent<{
  data: RecommendPlanet;
}> = function ({ data }) {
  return (
    <div className="flex items-center">
      <img
        alt="avatar"
        loading="lazy"
        src={data.avatar || "/assets/default-user-avatar.svg"}
        className="!w-[52px] !h-[52px] !rounded-full !flex-shrink-0"
      ></img>
      <div className="!ml-4 !flex-1 !flex !justify-between">
        <div>
          <div className="!text-sm !font-bold !flex !justify-start">
            {data.name}
          </div>
          {data?.creator?.twitter && (
            <div className="!flex !items-center !mt-[3px]">
              <TwitterIcon className="!w-[12px] !h-[12px] !fill-gray1"></TwitterIcon>
              <div className="!ml-1 !text-sm !text-gray1">
                {data.creator.twitter?.username}
              </div>
            </div>
          )}
          <div className="!text-sm !text-primary !mt-[6px]">
            <span>{amountDesc(data.stakeInfo.stakerCount || 0)} Stakers</span>
            <span className="!px-1">/</span>
            <span>
              {amountDesc(parseFloat(data.stakeInfo.stakedAmount))} KAT
            </span>
          </div>
        </div>
        <div className="!flex-shrink-0 !flex !items-center">
          <Button
            className="!bg-primary !h-[40px] !px-[10px] !text-sm !font-semibold !text-dark1"
            radius="xl"
            onClick={(e) => {
              e.stopPropagation();
              Router.push(`/space/${data.id}/staking`);
            }}
          >
            Stake
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SpaceListItem;
