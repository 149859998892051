import { use100vh } from "react-div-100vh";
import { useTypedSelector } from "@/stores";
import useRequest from "@/hooks/useRequest";
import { RecommendPlanet } from "@/types/api";
import { useCallback, useState, useEffect } from "react";
import Image from "next/image";
import TouchableHighlight from "@/components/common/TouchableHighlight";
import store, { actions } from "@/stores";
import { TextInput, Switch, Button } from "@mantine/core";
import EnterPlanetDialog from "@/components/planet/EnterPlanetDialog";
import { useDisclosure } from "@mantine/hooks";
import Router from "next/router";
import { useDebouncedValue } from "@mantine/hooks";
import SpaceListItem from "@/components/planet/SpaceListItem";
import AppPage from "@/components/common/AppPage";
import KatBalance from "@/components/common/KatBalance";
import { ExploreTab } from "@/types/store";
import { enableFeature, disableFeature } from "@/services/features";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const TabList = [
  {
    label: "Top Staked",
    tab: ExploreTab.amount,
  },
  {
    label: "Trending",
    tab: ExploreTab.incr,
  },
];

function Header({ mode }: { mode: any }) {
  return (
    <div className={`p-6 bg-dark1 flex-shrink-0`}>
      <div
        className={`transition-all ${mode === "default" ? "h-20" : "h-0"}`}
      ></div>
      <div className="flex items-center justify-between">
        <div className="text-white font-bold text-[28px]">Spaces</div>
        <KatBalance></KatBalance>
      </div>
    </div>
  );
}

export default function Explore() {
  const height = use100vh();
  const [opened, { open, close }] = useDisclosure(false);
  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword] = useDebouncedValue(keyword, 200);
  const [slideIndex, setSlideIndex] = useState(0);

  const { spacesTab: tab } = useTypedSelector((state) => {
    return state.ui;
  });

  const featureList = useTypedSelector((state) => {
    return state.features.list;
  });

  useEffect(() => {
    const tabIndex = TabList.findIndex((tabItem) => tabItem.tab === tab);
    setSlideIndex(tabIndex);
  }, [tab]);

  const handleCarouselChange = (index: number) => {
    const selectedTab = TabList[index]?.tab;
    if (selectedTab && selectedTab !== tab) {
      setTab(selectedTab);
    }
  };

  const setTab = useCallback((tab: ExploreTab) => {
    store.dispatch(actions.ui.setSpaceTab(tab));
  }, []);

  const { data, isLoading } = useRequest<{
    amount: RecommendPlanet[];
    incr: RecommendPlanet[];
  }>(
    {
      function: "chat/recommends",
      data: {},
      header: {
        endpoint: "network",
      },
    },
    {
      keepPreviousData: true,
    }
  );

  const { data: searchResult = [], isLoading: searching } = useRequest<
    RecommendPlanet[]
  >(
    debouncedKeyword
      ? {
          function: "chat/searchGroup",
          data: {
            query: debouncedKeyword,
            type: "user_space",
          },
        }
      : null
  );
  // const items = data?.[tab] || [];
  const spaceData =
    data && Object.entries(data).map((items) => items?.[1] || []);
  const loading = isLoading && !data;

  return (
    <div
      className="overflow-hidden select-none flex flex-col"
      style={{
        height: height ? `${height}px` : "100vh",
      }}
    >
      <AppPage
        loading={false}
        transition="none"
        Header={Header}
        keepScroll={true}
        scrollTop={0}
      >
        <div className="h-full flex flex-col relative">
          <div className="flex-1">
            <div
              className={`w-full bg-white px-6 pt-6 sticky top-0 z-[9]
               ${keyword ? "pb-0" : "pb-4"}
               `}
            >
              <TextInput
                classNames={{
                  input: "!rounded-full !px-6 !text-xs focus:!border-primary",
                }}
                size="xl"
                rightSection={
                  keyword ? (
                    <div
                      className="text-xs text-gray1"
                      onClick={() => {
                        setKeyword("");
                      }}
                    >
                      Cancel
                    </div>
                  ) : null
                }
                rightSectionWidth={keyword ? 70 : 40}
                placeholder="Search spaces by username or ID"
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.currentTarget.value);
                }}
              ></TextInput>
              {keyword && (
                <div className="mt-4 border-b sticky top-24 z-[9]"></div>
              )}
            </div>
            {keyword ? (
              <div>
                {searching ? (
                  <div className="h-[40vh] flex items-center justify-center">
                    <Image
                      src="/assets/loading.gif"
                      width={50}
                      height={50}
                      alt="loading"
                    ></Image>
                  </div>
                ) : (
                  <div className="px-6 mt-4 flex flex-col gap-10">
                    {keyword === "TNWOtIl5" && (
                      <>
                        <div className="flex items-center justify-between">
                          <div className="font-semibold">Debug mode</div>
                          <Switch
                            checked={featureList.vConsole}
                            color="orange"
                            size="md"
                            onChange={(event) => {
                              console.log(
                                "event.currentTarget.checked",
                                event.currentTarget.checked
                              );
                              if (event.currentTarget.checked) {
                                enableFeature("vConsole");
                              } else {
                                disableFeature("vConsole");
                              }
                            }}
                          ></Switch>
                        </div>
                      </>
                    )}

                    {keyword === "AN1Ot3l1" && (
                      <>
                        <div className="flex items-center justify-between">
                          <div className="font-semibold">Custom user login</div>
                          <Button
                            color="orange"
                            radius="xl"
                            size="xs"
                            onClick={() => {
                              store.dispatch(
                                actions.ui.setCustomUserLoginModal({})
                              );
                            }}
                          >
                            login
                          </Button>
                        </div>
                      </>
                    )}

                    {searchResult.map((item, index) => {
                      return (
                        <TouchableHighlight
                          key={index}
                          hoverStyle={{
                            opacity: 0.95,
                            background: "#ffffff",
                          }}
                          onClick={() => {
                            if (item.joined) {
                              Router.push(`/space/${item.id}`);
                            } else {
                              Router.push(`/space/${item.id}/invite`);
                            }
                          }}
                        >
                          <SpaceListItem data={item}></SpaceListItem>
                        </TouchableHighlight>
                      );
                    })}
                  </div>
                )}
              </div>
            ) : (
              <div className="">
                <div className="pt-4 bg-white sticky top-[100px] z-[9]">
                  <div className="grid grid-cols-2 mx-6 border-b">
                    {TabList.map((item) => {
                      const isActive = item.tab === tab;
                      return (
                        <div
                          className={`flex flex-col items-center justify-center rounded-full  ${
                            isActive
                              ? " text-primary font-bold"
                              : " text-dark1 font-semibold"
                          }`}
                          key={item.tab}
                          onClick={() => {
                            setTab(item.tab);
                            const tabIndex = TabList.findIndex(
                              (tabItem) => tabItem.tab === item.tab
                            );
                            setSlideIndex(tabIndex);
                          }}
                        >
                          {item.label}
                          <div
                            className={`h-[3px] rounded-full w-full mt-3  ${
                              isActive ? "bg-primary" : ""
                            }`}
                          ></div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {loading ? (
                  <div className="h-[40vh] flex items-center justify-center">
                    <Image
                      src="/assets/loading.gif"
                      width={50}
                      height={50}
                      alt="loading"
                    ></Image>
                  </div>
                ) : (
                  <Carousel
                    showArrows={false}
                    showIndicators={false}
                    showStatus={false}
                    showThumbs={false}
                    selectedItem={slideIndex}
                    onChange={handleCarouselChange}
                    emulateTouch
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={80}
                  >
                    {spaceData &&
                      spaceData.map((items, index) => {
                        return (
                          <div
                            className="px-6 mt-4 flex flex-col gap-10"
                            key={index}
                          >
                            {items &&
                              items.map((item, index) => {
                                return (
                                  <TouchableHighlight
                                    key={index}
                                    hoverStyle={{
                                      opacity: 0.95,
                                      background: "#ffffff",
                                    }}
                                    onClick={() => {
                                      if (item.joined) {
                                        Router.push(`/space/${item.id}`);
                                      } else {
                                        Router.push(`/space/${item.id}/invite`);
                                      }
                                    }}
                                  >
                                    <SpaceListItem data={item}></SpaceListItem>
                                  </TouchableHighlight>
                                );
                              })}
                          </div>
                        );
                      })}
                  </Carousel>
                )}
              </div>
            )}

            <div className="h-80"></div>
          </div>
        </div>
      </AppPage>

      <EnterPlanetDialog opened={opened} close={close}></EnterPlanetDialog>
    </div>
  );
}
