import { FunctionComponent, useCallback, useState } from "react";
import useAccount from "@/hooks/useAccount";
import { katAmountDesc } from "@/utils/format";
import { KATIcon } from "./icons";
import TouchableHighlight from "./TouchableHighlight";
import Router from "next/router";
import BigNumber from "bignumber.js";

const KatBalance: FunctionComponent = function () {
  const { balance, v1KATbalance } = useAccount();

  return (
    <TouchableHighlight
      defaultStyle={{
        borderRadius: "60px",
        background: "#FFEBE5",
      }}
      hoverStyle={{
        opacity: 0.8,
      }}
      onClick={() => {
        Router.push("/staking");
      }}
    >
      <div className="py-2 px-3 rounded-full text-sm font-semibold flex items-center">
        <KATIcon></KATIcon>
        <span className="ml-1">
          {katAmountDesc(
            parseFloat(
              balance.plus(v1KATbalance).toFixed(0, BigNumber.ROUND_FLOOR)
            )
          )}{" "}
          KAT
        </span>
      </div>
    </TouchableHighlight>
  );
};

export default KatBalance;
