import { useEffect, useState, useRef } from "react";
import { useScroll } from "react-use";
import { useRouter } from "next/router";
import store from "store2";

type Mode = "default" | "small";

export function usePageHeaderAnimate(offset?: number, keepScroll?: boolean) {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { y } = useScroll(scrollRef);
  const [mode, setMode] = useState<Mode>("default");
  const state = useRef({
    y: -1,
    lastTime: Date.now(),
  });
  const router = useRouter();
  const [tracking, setTracking] = useState(false);

  const storeKey = `state_${router.pathname}`;

  useEffect(() => {
    const handleRouteChange = () => {
      store.set(storeKey, {
        y: state.current.y,
        mode: mode,
      });
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events, state, storeKey, mode]);

  useEffect(() => {
    const storeValue = store.get(storeKey);
    if (storeValue) {
      if (keepScroll) {
        scrollRef.current?.scrollTo(0, storeValue.y);
        setMode(storeValue.mode || "default");
      }
      store.remove(storeKey);
    }
    setTimeout(() => {
      setTracking(true);
    }, 800);
  }, [storeKey, keepScroll]);

  useEffect(() => {
    if (!tracking) {
      return;
    }
    if (y <= (offset || 50)) {
      setMode("default");
      return;
    }
    if (state.current.y === -1) {
      state.current.y = y;
      return;
    }

    if (state.current.y === -1) {
      state.current.y = y;
      state.current.lastTime = Date.now();
      return;
    }
    if (Date.now() - state.current.lastTime > 150) {
      if (y >= state.current.y) {
        setMode("small");
      } else if (y < (offset || 50)) {
        setMode("default");
      }
      state.current.lastTime = Date.now();
    }

    state.current.y = y;
  }, [y, offset, tracking]);

  return {
    mode,
    scrollRef,
  };
}
