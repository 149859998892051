import { FunctionComponent, useCallback, useState } from "react";
import { Modal, Button } from "@mantine/core";
import TouchableHighlight from "../common/TouchableHighlight";
import Router from "next/router";
import { getPlanet, goToPlanet } from "@/services/chat";
import { toast } from "react-toastify";
import ReactCodeInput from "react-code-input";

const EnterPlanetDialog: FunctionComponent<{
  opened: boolean;
  close: any;
}> = function ({ opened, close }) {
  const [isFocus, setFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const enterPlanet = useCallback(async () => {
    if (loading || !value) {
      return;
    }
    try {
      setLoading(true);
      const planet = await getPlanet({
        id: String(Number(value)),
      });
      if (!planet.joined) {
        Router.push(`/space/${planet.id}/invite`);
      } else {
        goToPlanet(String(Number(value)));
      }
      close();
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [loading, value]);

  return (
    <Modal
      opened={opened}
      centered
      withCloseButton={false}
      onClose={close}
      size={383}
      radius={40}
      zIndex={1000}
      classNames={{
        content: "!bg-dark1 !py-10 !px-6",
        body: "!p-0",
      }}
    >
      <div className="flex flex-col items-center justify-center text-white">
        <div className="font-bold text-[28px] relative flex justify-center w-full ">
          <span>Enter Space ID</span>
        </div>
        <div className="mt-6 w-full flex justify-center">
          <ReactCodeInput
            type="number"
            name="code"
            fields={3}
            inputMode="numeric"
            onChange={(code) => {
              setValue(code);
            }}
          />
        </div>
        <Button
          className="mt-8 !h-[60px] !bg-primary !text-dark1 !text-base !font-bold"
          radius="xl"
          fullWidth
          onClick={enterPlanet}
          loading={loading}
        >
          Confirm
        </Button>
        <TouchableHighlight
          theme="text"
          className="mt-4 flex items-center"
          display="flex"
          onClick={close}
        >
          <div className="font-semibold text-primary">Cancel</div>
        </TouchableHighlight>
      </div>
    </Modal>
  );
};

export default EnterPlanetDialog;
