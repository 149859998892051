import { FunctionComponent, PropsWithChildren, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Image from "next/image";
import { usePageHeaderAnimate } from "@/hooks/ui/usePageHeaderAnimate";

const AppPage: FunctionComponent<
  {
    loading: boolean;
    error?: string;
    className?: string;
    transition?: string;
    Header?: any;
    animateHeader?: any;
    title?: string;
    keepScroll?: boolean;
    scrollTop?: number;
  } & PropsWithChildren
> = function ({
  loading,
  error,
  className = "h-full",
  transition,
  Header,
  animateHeader,
  children,
  title,
  keepScroll,
  scrollTop,
}) {
  const { scrollRef, mode } = usePageHeaderAnimate(undefined, keepScroll);

  useEffect(() => {
    if (typeof scrollTop !== "undefined") {
      scrollRef.current?.scrollTo({
        top: scrollTop,
      });
    }
  }, [scrollTop]);

  let animateData = {};
  if (transition !== "none") {
    animateData = {
      initial: { translateX: "100%", opacity: 0 },
      animate: { translateX: "0%", opacity: 1 },
      exit: { translateX: "0%" },
      transition: {
        delay: 0,
        duration: 0.2,
      },
    };
  }
  return (
    <AnimatePresence>
      <motion.div className={className} {...animateData}>
        <div className="h-full flex flex-col">
          {Header && (
            <div className="flex-shrink-0">
              <Header mode={mode} title={title}></Header>
            </div>
          )}

          <div
            className="flex-1 overflow-y-scroll"
            ref={Header ? scrollRef : null}
          >
            {loading ? (
              <div className="h-full w-full flex items-center justify-center">
                <Image
                  src="/assets/loading.gif"
                  width={50}
                  height={50}
                  alt="loading"
                ></Image>
              </div>
            ) : (
              children
            )}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AppPage;
